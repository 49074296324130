@import "../../../../variables.scss";

.sliderContainer {
  height: 108px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #213305;
}

.scrollParent {
  position: relative;
  width: 100vw;
  height: 20rem;
  padding: 2rem 0;
  overflow-x: hidden;

  h1 {
    color: $text;
    text-align: center;
    font-family: "boska-italic";
    font-size: 45px;
    font-weight: 400;
    line-height: normal;

    span {
      font-family: 'boska-mediumitalic';
    }
  }
}

.scrollElement {
  width: inherit;
  height: inherit;
  position: absolute;
  left: 0%;
  top: 0%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.scrollElement img {
  width: 15%;
}

.primary {
  animation: primary 20s linear infinite;
}

.secondary {
  animation: secondary 20s linear infinite;
}

@keyframes primary {
  from {
    left: 0%;
  }
  to {
    left: -100%;
  }
}

@keyframes secondary {
  from {
    left: 100%;
  }
  to {
    left: 0%;
  }
}

@media (max-width: 768px) {

  .scrollParent {
    width: 200vw;

    .scrollElement {
      gap: 100px;
    }
  }
  
  .sliderContainer {
    height: 63px;

    h1 {
      font-size: 30px;
      display: flex;
      flex-direction: row;
      white-space: nowrap;
      gap: 0.5rem;
    }
  }

  .secondary {
    display: none;
  }
}