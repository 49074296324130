@import "../../variables.scss";

.footerSection {
  position: relative;
  height: 313px;
  background-color: $primary;
  width: 100%;
  display: flex;
  justify-content: center;
}

.copyright {
  position: absolute;
  top: 13px;
  right: 150px;
  color: $secondary;
  font-family: Arial;
  font-size: 16.57px;
  font-weight: 400;
  text-shadow: 0px 8.285px 20.713px rgba(0, 0, 0, 0.05);
}

.footerContent {
  position: relative;
  width: 1280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: $secondary;
  padding: 0 150px;
}

.footerTabs {
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.footerTab {
  display: flex;
  flex-direction: column;
  width: 200px;
  
  p {
    color: $secondary;
    text-shadow: 0px 8.285px 20.713px rgba(0, 0, 0, 0.05);
    font-size: 18px;
    font-family: Arial;
    font-weight: 400;
    line-height: 25px;
  }
}

.footerFeedback {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h1 {
    color: $secondary;
    font-family: "boska-mediumitalic";
    font-size: 24px;
    font-weight: 500;
    text-transform: capitalize;
  }

  input {
    border: none;
    outline: none;
    background: none;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    border-bottom: 1px solid $secondary;
    padding-bottom: 5px;
    width: 344px;
    font-size: 18px;
    font-family: Arial;
    font-weight: 400;
  }

  ::placeholder {
    color: rgba(243, 248, 238, 0.60);
  }
}

.logoSection {
  position: relative;
  bottom: 0;
}

.footerLogo {
  width: 1280px;
  display: flex;
  justify-content: space-between;
}

.desktopLogo {
  display: flex;
}

.mobileLogo {
  display: none;
}

@media (max-width: 1280px) {

  .footerSection {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .copyright {
    right: 20px;
  }

  .footerContent {
    width: 100%;
    padding: 0 20px;

    .footerTabs {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 10px;

      .footerTab {
        margin-bottom: 20px;
        max-width: 40%;
      }

      .footerFeedback {
        margin-bottom: 80px;
      }
    }
  }

  .logoSection {
    width: 105%;
  }

  .desktopLogo {
    display: none;
  }

  .mobileLogo {
    display: flex;
  }

  .footerLogo {
    width: 100%;
    gap: 3px;

    svg {
      height: 35px;
      width: auto;
    }
  }
}