@import "../../variables.scss";

.instancesSection {
  margin-top: 170px;
  padding: 0 150px;

  .sectionHeader {
    margin-bottom: 60px;

    h1 {
      color: $text;
      text-align: center;
      font-family: "Boska-italic";
      font-size: 45px;

      span {
        font-family: "boska-mediumitalic";
      }
    }
  }

  .instances {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;

    .instance {
      width: 33%;
      padding: 23px 23px 16px 15px;
      width: 308px;
      height: 204px;
      border-radius: 25px;
      background: #e3bbe4;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        height: 100%;
        width: 100%;

        .left {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;

          .title {
            color: $text;
            font-family: "Arial";
            font-weight: 400;
            color: $text;
            font-size: 32px;
            text-transform: uppercase;
            letter-spacing: -1px;
          }
    
          .description {
            border-radius: 14.653px;
            border: 0.458px solid #213305;
            padding: 3px 18px;
            display: flex;
            margin-top: 20px;
    
            p {
              color: $text;
              text-shadow: 0px 5.677px 14.192px rgba(0, 0, 0, 0.05);
              font-family: "Arial";
              font-size: 18px;
              font-style: normal;
              font-weight: 405;
              line-height: normal;
            }
          }
        }

        .right {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: flex-end;
          width: 100%;
        }
      }
    }

    .instance:nth-child(2) {
      background: linear-gradient(0deg, rgba(33, 51, 5, 0.50) 0%, rgba(33, 51, 5, 0.50) 100%), url(https://uploads.top/api/v2/upload/0387d0dc8673d25e) no-repeat center;
      background-size: 100% auto;
      background-position: center center;
      color: #F0F9ED;

      .title {
        color: #F0F9ED;
      }

      .description {
        border: 0.458px solid #F0F9ED !important;

        p {
          color: #F0F9ED !important;
        }
      }

      .content {
        .left {
          .title {
            color: #F0F9ED;
          }
        }
      }
    }
    .instance:nth-child(3) {
      background: #E1FA00;
      color: #213305;

      .title {
        color: #213305;
        text-transform: none !important;
      }

      .description {
        border: 0.458px solid #213305 !important;

        p {
          color: #213305 !important;
        }
      }

      .content {
        .left {
          .title {
            color: #213305;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .instancesSection {
    padding: 0 20px;
    margin-top: 90px;

    .sectionHeader {
      margin-bottom: 28px;
      h1 {
        font-size: 32px;
      }
    }

    .instances {
      .instance {
        width: 100%;
        height: 167px;
        .content {
          .left {
            .title {
              font-size: 24px;
            }

            .description {
              margin-top: 30px;
            }
          }
        }
      }
    }
  }
}