@import './variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: "boska";
  src: url("./Boska-Regular.otf");
}
@font-face {
  font-family: "boska-italic";
  src: url("./Boska-Italic.otf");
}
@font-face {
  font-family: "boska-medium";
  src: url("./Boska-Medium.otf");
}
@font-face {
  font-family: "boska-mediumitalic";
  src: url("./Boska-MediumItalic.otf");
}

@font-face {
  font-family: "Florensa Demo";
  src: url("./Florensa-Demo.ttf");
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  font-family: 'Outfit', sans-serif;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

html::-webkit-scrollbar {
  display: none;
}

body {
  color: $text;
  background: $background;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  padding: 0.5rem;
  font-size: large;
  background-color: $primary;
  border: none;
  border-radius: 4px;
  font-family: 'Outfit';
  cursor: pointer;
}