@import "../../variables.scss";

.sponsorContainer {
  padding: 0 150px;
  margin-top: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 190px;
}

.title {
  font-size: 55px;
  font-weight: 400;
  font-family: "boska-italic";
  margin-bottom: 50px;
  color: $text;

  span {
    font-family: "boska-mediumitalic";
  }
}

.sponsorCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
  gap: 50px;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px;
  background-color: #e3bbe4;
  border-radius: 15.061px;
  width: 311px;
  height: 654px;
  color: $text;

  .cardContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    h1 {
      font-size: 27.474px;
      font-family: Arial;
      font-weight: 400;
      text-transform: uppercase;
    }

    h2 {
      margin-top: 30px;
      font-size: 53.72px;
      font-family: Florensa Demo;
      font-weight: 400;
    }

    hr {
      border: 1px solid $text;
    }

    .tags {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 16px;
    }

    .tag {
      border-radius: 14.653px;
      border: 0.458px solid $text;
      padding: 3px 8px;
      margin-top: 16px;

      p {
        font-size: 18.316px;
        font-family: Arial;
        font-weight: 400;
        text-shadow: 0px 5.677px 14.192px rgba(0, 0, 0, 0.05);
        color: $text;
      }
    }

    .cardTop {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    .cardBottom {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    .sponsorStar {
      margin-bottom: 16px;
    }

    .sponsorButton {
      width: 100%;
      display: flex;
      gap: 13px;
      align-items: center;

      p {
        font-size: 30.123px;
        font-family: "boska-mediumitalic";
        color: $text;
      }
    }
  }
}

.card:nth-child(2) {
  background-color: $primary;

  .cardContent {
    h1 {
      color: #F0F9ED;
    }

    h2 {
      color: #F0F9ED;
    }

    hr {
      border: 1px solid #F0F9ED;
    }

    .tag {
      border: 0.458px solid #F0F9ED;

      p {
        color: #F0F9ED;
      }
    }

    .sponsorButton {
      p {
        color: #F0F9ED;
      }
    }
  }
}

.card:nth-child(3) {
  background-color: $quaternary;

  .cardContent {
    h1 {
      color: $text;
    }

    h2 {
      color: $text;
    }

    hr {
      border: 1px solid $text;
    }

    .tag {
      border: 0.458px solid $text;

      p {
        color: $text;
      }
    }

    .sponsorButton {
      p {
        color: $text;
      }
    }
  }
}


@media (max-width: 768px) {
  .sponsorContainer {
    padding: 0 20px;
    margin-top: 77px;
    margin-bottom: 20px;

    .title {
      font-size: 32px;
      margin-bottom: 27px;
    }

    .sponsorCards {
      flex-direction: column;
      gap: 20px;
      width: 100%;

      .card {
        width: 100%;
        height: auto;

        .cardContent {
          .cardTop {
            h1 {
              font-size: 24px;
            }

            h2 {
              font-size: 45px;
            }

            hr {
              width: 70%;
            }

            .tags {
              .tag {
                p {
                  font-size: 18px;
                }
              }
              
              .andMore {
                display: flex;
              }

              .more {
                display: none;
              }
            }
          }

          .cardBottom {
            margin-top: 38px;
          }
        }
      }
    }
  }
}