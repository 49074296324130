@import "../../variables.scss";

.header {
  width: 100%;
  height: 90vh;
  background-size: cover;
  display: flex;
  flex-direction: row;
}

.title {
  color: #FFF;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.10);
  font-family: Arial;
  font-size: 75px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;

  span {
    font-family: "boska-mediumitalic";
  }
}

.levels {
  height: 128px;
  flex-shrink: 0;
  background-color: $quaternary;
  display: flex;
  justify-content: center;
  gap: 110px;
}

.level {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;

  h2 {
    color: $text;
    text-align: center;
    font-family: Arial;
    font-size: 40px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
}

.headerLeft {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #213305;
  padding: 50px 40px;

  .textRow {
    display: flex;
    gap: 14px;
    margin-bottom: 20px;
  }
}

.headerRight {
  width: 50%;
  background: url(https://uploads.top/api/v2/upload/f2c8ab766ec52199) no-repeat;
  background-size: cover;
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    height: auto;

    .headerLeft {
      width: 100%;
      padding: 50px 20px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 7px;

      .textRow {
        gap: 5px;

        svg {
          height: 54px;
          width: auto;
        }
      }
    }

    .headerRight {
      width: 100%;
      height: 300px;
    }
  }

  .levels {
    display: flex;
    flex-direction: row;
    height: 72px;
    justify-content: space-between;
    width: 100%;
    gap: 0;
    padding: 0 20px;

    .level {
      gap: 10px;
      display: flex;
      flex-direction: row;

      h2 {
        font-size: 24px;
      }
    }
  }
}