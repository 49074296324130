@import "../../variables.scss";

@font-face {
  font-family: "florensa";
  src: url("../../Florensa-Demo.ttf") format("truetype");
}

.container {
  display: flex;
  flex-direction: column;
  padding: 0 150px;
  align-items: flex-start;
}

.box {
  background-color: $tertiary;
  color: $secondary;
  border-radius: 28px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  width: 499px;

  h3 {
    margin-top: 23px;
    color: $secondary;
    text-shadow: 0px 5.595px 13.987px rgba(0, 0, 0, 0.05);
    font-family: Arial;
    font-size: 18.052px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .resultTitle {
    font-family: "boska-mediumitalic";
  }

  .outputText {
    color: $secondary;
    font-family: Arial;
    font-size: 31px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    margin-top: 9px;
  }
}

// Input

.input {
  background-color: transparent;
  color: $secondary;
  padding: 0;
  color: #f3f8ee;
  font-family: "florensa";
  font-size: 38.119px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

input {
  border: none; /* Removes the default border */
}

input:focus {
  outline: none; /* Removes the border when the input is clicked */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.inputTitle {
  color: #f3f8ee;
  text-shadow: 0px 5.594833850860596px 13.98708438873291px rgba(0, 0, 0, 0.05);
  font-size: 18.052px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.inputBox {
  border-radius: 28px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 370px;
}

.line {
  width: 80%;
  height: 1px;
  background-color: $secondary;
}

.inputError {
  color: $error;
}

.transparentButton {
  background-color: transparent;
  border: none;
  color: $secondary;
  font-size: 18.052px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: underline;
  cursor: pointer;
}

.depthCalculator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .description {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 700px;
    margin-top: 128px;
    margin-bottom: 80px;

    h1 {
      font-family: "boska-italic";
      color: $text;
      font-size: 55px;
      margin-bottom: 33px;

      span {
        font-family: "boska-mediumitalic";
      }
    }

    p {
      color: #000;
      text-align: center;
      font-family: Arial;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.depthContent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
}

.calculatorBox {
  background-color: $tertiary;
  color: $secondary;
  border-radius: 28px;
  padding: 30px;
  display: flex;
  flex-direction: row;
  width: 499px;
}

.rightSide {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
}

@media (max-width: 768px) {
  .container {
    padding: 0 20px;
  }

  .box {
    width: 100%;
  }

  .inputBox {
    width: 100%;
  }

  .calculatorBox {
    width: 100%;
  }

  .depthContent {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .depthCalculator {
    .description {
      width: 100%;
      margin-top: 32px;
      margin-bottom: 37px;

      h1 {
        font-size: 32px;
        margin-bottom: 12px;
      }

      p {
        font-size: 14px;
      }
    }

    .calculatorBox {
      width: 100%;

      .leftSide {
        width: 80%;
      }

      .rightSide {
        width: 20%;

        .transparentButton {
          display: flex;
          justify-content: flex-end;
          padding: 0;
        }
      }
    }
  }

  .rightSide {
    width: 100%;
  }

  .input {
    font-size: 30px;
  }

  .inputTitle {
    font-size: 16px;
  }

  .inputBox {
    width: 100%;
  }

  .calculatorBox {
    width: 100%;
  }

  .box {
    width: 100%;
  }

  .resultTitle {
    font-size: 30px;
  }

  .outputText {
    font-size: 50px;
  }

  .line {
    width: 100%;
  }
}