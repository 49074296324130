@import "../../../variables.scss";

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 47px;
  padding: 54px 20px;
  text-align: center;

  h1 {
    font-family: "boska-italic";
    font-size: 51px;
  }

  span {
    font-family: "boska-mediumitalic";
  }

  p {
    font-family: Arial;
    font-size: 20px;
    font-weight: 400;
  }
}

@media (max-width: 768px) {
  .header {
    gap: 20px;

    h1 {
      font-size: 32px;
    }

    p {
      font-size: 16px;
    }
  }
}