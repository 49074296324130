@import "../../../variables.scss";

.articles {
  .article {
    border-bottom: $primary 1px solid;
    padding: 27px 150px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  
    .left {
      width: 45%;
  
      img {
        width: 402px;
        height: 401px;
        object-fit: cover;
      }
    }
  
    .right {
      width: 55%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
  
      h1 {
        font-family: "florensa";
        font-size: 32px;
      }
      
      h2 {
        margin-top: 20px;
        font-family: "boska-italic";
        font-size: 32px;
        text-transform: capitalize;
  
        span {
          svg {
            position: absolute;
            margin-left: -60px;
            margin-top: 2px;
          }
        }
      }
  
      .description {
        color: #000;
        font-family: Arial;
        font-size: 16px;
        width: 396px;
        margin-top: 30px;
      }
  
      .bottomRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
  
        .date {
          font-family: "florensa";
          font-size: 24px;
          color: $primary;
        }
  
        .readMoreBtn {
          border-radius: 21px;
          border: 1px solid #E45D2E;
          padding: 6px 30px;
          font-size: 24px;
          font-family: Arial;
          text-transform: capitalize;
        }
      }
    }
  }
  // second child
  .article:nth-child(2) {
    .right {

      h2 {
        margin-top: 20px;
        font-family: "boska-italic";
        font-size: 32px;
        text-transform: capitalize;
  
        span {
          svg {
            position: absolute;
            margin-left: -65px;
            margin-top: 4px;
          }
        }
      }

      .bottomRow {
        .readMoreBtn {
          border: 1px solid #E3BBE4;
        }
      }
    }
  }

  .article:nth-child(3) {
    margin-bottom: 60px;
    
    .right {

      h2 {
        margin-top: 20px;
        font-family: "boska-italic";
        font-size: 32px;
        text-transform: capitalize;
  
        span {
          svg {
            position: absolute;
            margin-left: -72px;
            margin-top: 4px;
          }
        }
      }

      .bottomRow {
        .readMoreBtn {
          border: 1px solid #E1FA00;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .articles {
    .article {
      padding: 29px 20px;
      flex-direction: column;

      .left {
        width: 100%;
        margin-bottom: 25px;

        img {
          width: 100%;
          height: auto;
          aspect-ratio: 1/1;
        }
      }

      .right {
        width: 100%;

        h2 {
          margin-top: 10px;
        }

        .description {
          width: 100%;
          font-size: 16px;
          margin-top: 20px;
        }

        .bottomRow {
          flex-direction: row;
          align-items: center;
          margin-top: 50px;

        }
      }
    }

    .article:last-child {
      border-bottom: none;
    }
  }
}