@import "../../variables.scss";

.quoteSection {
  margin-top: 150px;
}

.content {
  display: flex;
  flex-direction: row;
}

.left {
  height: 550px;
  background: url(https://uploads.top/api/v2/upload/0b53805b16225bfb) center / cover no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;
}

.right {
  background: $primary;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .quoteText {
    color: #fff;
    font-family: "boska";
    font-size: 45px;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    margin: 0;
    padding: 0;
    text-transform: capitalize;
    width: 339.452px;

    span {
      color: $quaternary;
      font-family: "boska-italic";
    }
  }

  .tipsButton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 55px;
    gap: 20px;

    .tips {
      border-radius: 20px;
      border: 0.549px solid #e1fa00;
  
      p {
        color: #fff;
        text-align: center;
        text-shadow: 0px 6.804px 17.009px rgba(0, 0, 0, 0.05);
        font-family: Arial;
        font-size: 21.952px;
        padding: 5px 15px;
        font-style: normal;
        font-weight: 405;
        line-height: normal;
      }
    }
  }
}


@media (max-width: 768px) {
  .quoteSection {
    margin-top: 60px;
  }
  .content {
    flex-direction: column;

    .left {
      width: 100%;
      height: 368px;
    }

    .right {
      width: 100%;
      height: 368px;

      .quoteText {
        font-size: 32px;
      }

      .tipsButton {
        margin-top: 48px;

        .tips {
          border: 1px solid #e1fa00;
          p {
            font-size: 20px;
          }
        }
      }
    }
  }
}