@import "../../variables.scss";

.navbar {
  z-index: 100;
  position: relative;
  width: 100%;
  height: 94px;
  background: #213305;
  color: $quaternary;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 150px;
  border-bottom: #E1FA00 1px solid;
}

.navLeft {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  height: 100%;
}

.navLogo {
  height: 95px;
  display: flex;
  width: auto;
}

.navRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 23px;

  a {
    color: #E1FA00;
    font-family: Arial;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }

  .button {
    color: #ffffff;
    padding: 10px 8px;
    cursor: pointer;
    border-radius: 18px;
    background: #e1fa00;
    color: #213305;
    font-family: Arial;
    font-size: 16px;
    font-style: italic;
    line-height: normal;
    text-transform: uppercase;
  }
}

.mobileMenu {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 23px;
  top: 94px;
  left: 0;
  width: 100%;
  background: #213305;
  color: $quaternary;
  padding: 0 150px;
  z-index: 1;
}

// mobile responsive
@media (max-width: 768px) {
  .navbar {
    padding: 0 20px;
  }

  .mobileMenu {
    padding: 0 20px;
  }

  .navItem {
    display: none;
  }

  .navLogo {
    height: 85px;
    display: flex;
    width: auto;
  }
}